import React from 'react';
import styled, { css, cx } from 'react-emotion';
import { StyledContainer } from './ui/Form';

const MessageWrapperContainer = styled(StyledContainer)`
  max-width: 500px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  p {
    &:first-of-type {
      color: #000;
      text-align: left;
      font-size: 2.5rem;
    }

    color: #333;
    line-height: 1.25em;
    padding: 0 0 1em 0;
    text-align: justify;
    font-size: 2rem;
  }

  .inner-container {
    width: 40rem;
    padding: 10px;

    span {
      display: block;
      margin-bottom: 1rem;
      font-size: 1.6rem;
    }

    .heading {
      font-weight: 600;
      font-size: 1.8rem;
    }
  }
`;

const MessageText = () => {
  return (
    <MessageWrapperContainer>
      <div className="inner-container">
        <p className="heading">Fick du inget mail via Glömt ditt lösenord?</p>
        <span>
          Det betyder att du inte har ett registrerat inloggningskonto hos oss i
          vår webbutik.
        </span>
        <span>
          Gå till kassan, fyll i dina uppgifter och bocka i rutan ”Skapa ett
          inloggningskonto”. När du slutfört din beställning är
          inloggningskontot skapat.
        </span>
        <span>
          Observera att det går att ha flera inloggningskonton till samma
          kyrka/skola.
        </span>
      </div>
    </MessageWrapperContainer>
  );
};

export default MessageText;
