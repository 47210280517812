import React from 'react';
import styled, { css } from 'react-emotion';
import Container from '../../Layout/Container';

export const StyledContainer = styled(Container)`
  align-items: center;
`;

export const Header = styled('h1')`
  margin-bottom: 15px;
  ${({ theme }) => theme.fontSizes.hero};
`;

export const smallSection = css`
  display: flex;
  flex-direction: column;
  width: 40rem;
  max-width: 100%;
  padding: 10px;

  label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
`;

export const activeSegment = css`
  position: relative;

  ::before {
    content: '';
    position: absolute;
    left: -1.5rem;
    top: 0;
    bottom: 0;
    width: 3px;
    height: 100%;
    background-color: #5e85ff;
    background: linear-gradient(to bottom, #5e85ff, #ffc7b6);
    transition: opacity 0.4s ease;
  }
`;
