import AuthContext from '@jetshop/core/components/AuthContext/AuthContext';
import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import theme from '@jetshop/ui/utils/theme';
import t from 'format-message';
import React, { Component } from 'react';
import styled, { css, cx } from 'react-emotion';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import TrendButton from '../ui/Button';
import {
  StyledContainer,
  Header,
  activeSegment,
  smallSection
} from './ui/Form';
import MessageText from './MessageText';

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
`;

const actionWrapper = css`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    flex: 1 1 calc(50% - 1em);
  }
`;

const TextLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  text-align: right;
  text-decoration: none;
  ${({ theme }) => theme.fontSizes.description};

  :hover {
    text-decoration: underline;
  }

  &.signup-link {
    margin-left: auto;
  }
`;

// const MessageWrapper = styled.div`
//   max-width: 500px;
//   margin-bottom: 1.5rem;
//   margin-top: 1.5rem;
//   p {
//     &:first-of-type {
//       color: #000;
//       text-align: left;
//       font-size: 2.5rem;
//     }

//     color: #333;
//     line-height: 1.25em;
//     padding: 0 0 1em 0;
//     text-align: justify;
//     font-size: 2rem;
//   }
// `;

// const MessageWrapperContainer = styled(StyledContainer)`
//   max-width: 500px;
//   margin-bottom: 1.5rem;
//   margin-top: 1.5rem;
//   p {
//     &:first-of-type {
//       color: #000;
//       text-align: left;
//       font-size: 2.5rem;
//     }

//     color: #333;
//     line-height: 1.25em;
//     padding: 0 0 1em 0;
//     text-align: justify;
//     font-size: 2rem;
//   }

//   .inner-container {
//     width: 40rem;
//     padding: 10px;

//     span {
//       display: block;
//       margin-bottom: 1rem;
//       font-size: 1.6rem;
//     }

//     .heading {
//       font-weight: 600;
//       font-size: 1.8rem;
//     }
//   }
// `;

// const LoginTempMessage = () => {
//   return (
//     <MessageWrapperContainer>
//       <div className="inner-container">
//         <p className="heading">Fick du inget mail via Glömt ditt lösenord?</p>
//         <span>
//           Det betyder att du inte har ett registrerat inloggningskonto hos oss i
//           vår webbutik.
//         </span>
//         <span>
//           Gå till kassan, fyll i dina uppgifter och bocka i rutan ”Skapa ett
//           inloggningskonto”. När du slutfört din beställning är
//           inloggningskontot skapat.
//         </span>
//         <span>
//           Observera att det går att ha flera inloggningskonton till samma
//           kyrka/skola.
//         </span>
//       </div>
//     </MessageWrapperContainer>
//   );
// };

class LogInPage extends Component {
  render() {
    return (
      <AuthContext.Consumer>
        {({ loggedIn }) => (
          <StyledContainer maxWidth marginTop="sm">
            {loggedIn ? (
              <Redirect to="/" /*to="/my-pages"*/ />
            ) : (
              <>
                <LogInFormProvider>
                  {({ globalError, isSubmitting, isValid }) => (
                    <section className={cx(smallSection, activeSegment)}>
                      <Header>Logga in</Header>

                      <Input type="email" name="email" label="E-post" />

                      <Input type="password" name="password" label="Lösenord" />

                      {globalError && (
                        <GlobalError css={{ marginBottom: '20px' }}>
                          {globalError}
                        </GlobalError>
                      )}

                      <section className={actionWrapper}>
                        <StyledTrendButton
                          type="submit"
                          disabled={!isValid}
                          loading={isSubmitting}
                          loadingText="Laddar..."
                        >
                          Logga in
                        </StyledTrendButton>

                        <TextLink to="/forgot-password">
                          Glömt ditt lösenord?
                        </TextLink>
                      </section>
                    </section>
                  )}
                </LogInFormProvider>
                <MessageText />
              </>
            )}
          </StyledContainer>
        )}
      </AuthContext.Consumer>
    );
  }
}
export default withRouter(LogInPage);
