import React, { useState, Fragment } from 'react';
import styled, { css } from 'react-emotion';
import { ErrorMessage, Field } from 'formik';
import get from 'lodash.get';
import t from '@jetshop/intl';
import {
  InputError,
  InputPositive,
  ToggleViewPasswordButton
} from './InputStatus';

const Input = styled('input')`
  height: 40px;
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  margin-bottom: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background: ${({ theme }) => theme.colors.lightGrey};
  ${({ theme }) => theme.fontSizes.regular}

  ${({ disabled }) =>
    disabled &&
    css`
      background: #ffffff;
      color: #808080;
    `}
`;

export const Wrapper = styled('div')`
  margin-bottom: 2rem;
`;

export const ErrorSpan = styled('span')`
  position: absolute;
  font-style: italic;
  color: ${({ theme }) => theme.colors.red};
  ${({ theme }) => theme.fontSizes.description}
`;

export const Label = styled('label')`
  display: block;
  margin-bottom: 0.5rem;
  ${({ theme }) => theme.fontSizes.description}

  .req {
    margin-left: 0.5em;
    color: hsl(0, 0%, 60%);
    font-size: 10px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: #808080;
    `}
`;

const InputWithLabel = ({
  label,
  disabled,
  error,
  success,
  warning,
  loading,
  name,
  required,
  type,
  disableValidation,
  ...props
}) => {
  const [currentType, setType] = useState(type);
  return (
    <Wrapper className={props.wrapperClassName}>
      <Label disabled={disabled} htmlFor={name}>
        <span>{label || name}</span>
        {required && <span className="req">(Obligatorisk)</span>}
      </Label>
      <Field
        disabled={disabled}
        id={name}
        name={name}
        render={({ field, form: { touched, errors } }) => (
          <div>
            <Input {...props} {...field} type={currentType} />
            {type === 'password' && (
              <ToggleViewPasswordButton
                className={currentType === 'password' ? 'hidden' : 'visible'}
                onClick={() => {
                  currentType === 'password'
                    ? setType('text')
                    : setType('password');
                }}
              />
            )}
            {!disableValidation && (
              <Fragment>
                {get(touched, field.name) && get(errors, field.name) && (
                  <InputError />
                )}
                {get(touched, field.name) && !get(errors, field.name) && (
                  <InputPositive />
                )}
              </Fragment>
            )}
          </div>
        )}
      />
      {!disableValidation && <ErrorMessage name={name} component={ErrorSpan} />}
    </Wrapper>
  );
};

export default InputWithLabel;
