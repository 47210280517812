import React, { Fragment } from 'react';
import styled, { cx } from 'react-emotion';
import {
  ForgotPasswordProvider,
  useForgotPasswordData
} from '@jetshop/core/components/Auth/ForgotPasswordProvider';
import Head from '@jetshop/core/components/Head';
import t from '@jetshop/intl';
import Container from '../Layout/Container';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import ButtonWithLoading from '../ui/Button';
import { activeSegment, smallSection } from './ui/Form';
import MessageText from './MessageText';

const StyledContainer = styled(Container)`
  align-items: center;
`;

const Header = styled('h1')`
  margin-bottom: 15px;
  ${({ theme }) => theme.fontSizes.hero};
`;

const Text = styled('p')`
  margin-bottom: 15px;
  ${({ theme }) => theme.fontSizes.regular};
`;

export default function ForgotPasswordPage(props) {
  return (
    <StyledContainer maxWidth marginTop="sm">
      <ForgotPasswordProvider>
        <section className={cx(smallSection, activeSegment)}>
          <Header>Glömt lösenord</Header>
          <Head data={{ title: 'Glömt lösenord' }} />
          <ForgotPasswordForm />
        </section>
      </ForgotPasswordProvider>
      <MessageText></MessageText>
    </StyledContainer>
  );
}

function ForgotPasswordForm() {
  const {
    submitted,
    isSubmitting,
    globalError,
    isValid,
    emailInputProps
  } = useForgotPasswordData();

  if (submitted) {
    return (
      <Text>
        Din begäran om återställning av lösenord har skickats in. Kolla in din
        e-post för en länk för att återställa ditt lösenord.
      </Text>
    );
  }

  return (
    <Fragment>
      <Input label={'E-post'} {...emailInputProps} />

      {globalError && (
        <GlobalError css={{ marginBottom: '1em' }}>{globalError}</GlobalError>
      )}

      <ButtonWithLoading
        loading={isSubmitting}
        type="submit"
        disabled={!isValid}
      >
        Skicka
      </ButtonWithLoading>
    </Fragment>
  );
}
