import React from 'react';
import { cx, css } from 'emotion';
import { theme } from '../Theme';
import { ReactComponent as NoticeSVG } from '../../svg/notice.svg';

export function GlobalError({ children, className }) {
  return (
    <div className={cx(error, className)}>
      <div>{children}</div>
      <aside>
        <NoticeSVG />
      </aside>
    </div>
  );
}

const error = css`
  display: flex;
  align-items: center;
  padding: 0.75em;
  color: ${theme.colors.yellow};
  background: ${theme.colors.lightGrey};
  ${theme.fontSizes.description}

  aside {
    margin-left: auto;
  }

  svg {
    height: 20px;
    width: 20px;
  }
`;
